<template>
  <div
    @drop.prevent="dropFiles"
    @dragenter.prevent
    @dragover.prevent
  >
    <v-file-input
      v-model="file"
      v-bind="$attrs"
      v-on="$listeners"
    ></v-file-input>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  inheritAttrs: false,
  props: {
    value: { default: () => ({}) },
  },
  setup(props, context) {

    const file = computed({
      get: () => props.value,
      set: (val) => {
        context.emit('input', val)
        context.emit('change', val)
      },
    })

    // const file = ref()

    // watch(() => props.value, val => {
    //   file.value = val
    // }, { immediate: true })

    // watch(file, val => {
    //   context.emit('input', val)
    // })

    function dropFiles(e) {
      const droppedFiles = Array.from(e.dataTransfer.files)
      if (droppedFiles.length) {
        file.value = droppedFiles[0]
      }
    }

    return {
      file,
      dropFiles,
    }

  },
}
</script>
